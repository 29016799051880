<template>
  <div class="manage-propertyCustomCategories animatedBox">
    <div class="container fluid">
      <div class="card">
        <!-- Table -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="propertyCustomCategoriesTableColumns"
          :rows="propertyCustomCategoriesTableData"
          :totalRows="propertyCustomCategoriesTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openPropertyDetail"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddPropertyCustomCategory">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn mr-1"
                @click="toEditPropertyCustomCategory(props.row.id)"
              >
                <i class="fas fa-pen"></i>
              </button>
              <!-- TODO: Wait for delete API -->
              <button
                class="btn danger"
                @click="deletePropertyCustomCategories(props.row.id)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-propertyCustomCategories",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      propertyCustomCategoriesTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      propertyCustomCategoriesTableData: [],
      propertyCustomCategoriesTablePagination: {},
      isFilterActive: false,
      filter: {
        referenceCode: "",
        "title[partial]": "",
        category: "",
        furnishingType: "",
        purpose: ""
      }
    };
  },
  mounted() {
    this.getAllPropertyCustomCategories({ limit: this.tableParams.perPage });
  },
  methods: {
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllPropertyCustomCategories();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllPropertyCustomCategories();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddPropertyCustomCategory() {
      this.$router.push({ name: "ManagePropertyCustomCategoriesAdd" });
    },
    toEditPropertyCustomCategory(id) {
      this.$router.push({
        name: "ManagePropertyCustomCategoriesEdit",
        params: { id: id }
      });
    },
    openPropertyDetail(event) {
      /* this.isPropertyDetailShown = true;
      this.isPropertyDetailLoading = true;
      this.getPropertyDetail(event.row.id).then(() => {
        this.isPropertyDetailLoading = false;
      }); */
    },

    // ============================= API Related ===============================
    async getAllPropertyCustomCategories(queries = {}) {
      this.$store.commit("setIsLoading", true);
      let vm = this;
      let data = await this.$store.dispatch(
        "managePropertyCustomCategories/getAllPropertyCustomCategories",
        {
          ...queries,
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        }
      );

      this.propertyCustomCategoriesTableData = this._.cloneDeep(data.data);
      this.propertyCustomCategoriesTablePagination = this._.cloneDeep(
        data.meta.pagination
      );
      this.$store.commit("setIsLoading", false);
    },
    async deletePropertyCustomCategories(id) {
      const c = confirm("Are you sure to delete?");

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          let data = await this.$store.dispatch(
            "managePropertyCustomCategories/deletePropertyCustomCategories",
            id
          );

          this.$store.commit("setIsLoading", false);
          this.getAllPropertyCustomCategories();
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          this.$store.commit("setIsLoading", false);
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-propertyCustomCategories {
}
</style>
